import React, { useState } from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { setBonusPointUser } from "../../request/user";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Controller } from "react-hook-form";

function SetBonusForm(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, getUserListInfo } = props;

  const [bonusPoint, setBonusPoint] = useState(0);
  const [wallet, setWallet] = useState("");

  const handleSubmitPopup = async () => {
    try {
      setIsOpen(false);

      const res = await setBonusPointUser({
        walletAddress: wallet,
        bonusPoint,
      });
      if (res.status !== 200) {
        dispatch(alertFailure("Something went wrong"));
        return;
      }
      await getUserListInfo("");
      dispatch(alertSuccess("Bonus points updated successfully"));
    } catch (err) {
      console.log(err);
      dispatch(alertFailure("Something went wrong"));
    }
  };

  const handleBonusPointChange = (event: any) => {
    setBonusPoint(Number(event.target.value));
  };

  return (
    <>
      <ConfirmDialog
        title="Set Bonus Points"
        open={isOpen}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={() => setIsOpen(false)}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Wallet</label>
          <input
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
            className={classes.formControlInput}
          />
        </div>

        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Bonus Points</label>
          <FormControl fullWidth>
            <Select
              style={{
                width: "100%",
                border: "1px solid #DFDFDF",
                display: "block",
                padding: "13px",
                marginTop: "5px",
                transition: ".1s all ease-in",
                borderRadius: "5px",
                backgroundColor: "white",

              }}
              labelId="bonus-point-label"
              id="bonus-point-select"
              value={bonusPoint}
              onChange={handleBonusPointChange}
            >
              <MenuItem disabled selected value=''>Select Points</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default SetBonusForm;
