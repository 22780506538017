import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import UserRow from "./UserRow";
import SearchForm from "./SearchForm";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  getUserList,
  reloadCachedUserList,
  exportUserList,
  wipeAllBonuses,
  importNftAllocation,
} from "../../request/user";

import { MenuItem, Select } from "@material-ui/core";
import { TIERS_LABEL, TIERS_VALUE } from "../../constants";
import SetBonusForm from "./SetBonusForm";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { importBonus } from "../../request/user";
import ExportBonusForm from "./ExportBonusForm";

const tableHeaders = [
  "WALLET",
  "TOTAL",
  "BONUS",
  "TIER",
  'MINTED NFT',
  'Nft Allocated',
  "EMAIL",
  "TELEGRAM",
  "UPDATED AT",
];

const WhiteListNft: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const perPage = 10;
  const [users, setUsers] = useState([]);
  const [selectedTier, setSelectedTier] = useState(-1);
  const [lastPage, setLastPage] = useState(1);

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isOpenBonusModal, setIsOpenBonusModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState("0");
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [selectedTierBonus, setSelectedTierBonus] = useState("0");

  const resetBonusFileInput = (fileInputRef: React.MutableRefObject<null>) => {
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.value = "";
    }
  };
  const getUserListInfo = async (query: any) => {
    const queryParams: any = {
      searchQuery: query,
      page: currentPage,
    };
    if (selectedTier >= 0) {
      queryParams.tier = selectedTier;
    }

    try {
      setLoading(true);
      const resObject = await getUserList(queryParams);
      if (resObject.status === 200) {
        setUsers(resObject.data.data);
        setLastPage(resObject.data.lastPage);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  const openPopupCreate = (e: any) => {
    setIsOpenEditPopup(true);
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSelectedTierChange = (event: any) => {
    setQuery("");
    setCurrentPage(1);
    setSelectedTier(Number(event.target.value));
  };

  const handleSelectedTierForBonus = (event: any) => {
    setSelectedTierBonus(event.target.value);
  };

  const handleSelectedBonusChange = (event: any) => {
    setSelectedBonus(event.target.value);
  };

  const handleCreateUpdateData = async () => {
    // if (selectedTier && selectedBonus === -1) {
    //   alert("Please Select Bonus First");
    //   return;
    // }
    try {
      //@ts-ignore
      await exportUserList(selectedTierBonus, selectedBonus);
      setIsOpenEditPopup(false);
      // setSelectedBonus(-1);
    } catch (e) {
      setIsOpenEditPopup(false);
      dispatch(alertFailure("Export CSV fail !!!"));
    }
  };

  const handleExportCSV = async () => {
    try {
      await exportUserList();
    }
    catch(e) {
      console.log(e)
    }
  }

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  
  useEffect(() => {
    const timer = setTimeout(() => {
      getUserListInfo(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query, currentPage, selectedTier]);

  const handleReloadCached = async () => {
    try {
      if (!window.confirm("This is a heavy task. Are you sure to continue?")) {
        return;
      }
      await reloadCachedUserList();
      dispatch(alertSuccess("Reloading, please be patient"));
    } catch (err: any) {
      dispatch(alertFailure("Reload failed!"));
    }
  };

  const handleWipeAllBonuses = async () => {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const res = await wipeAllBonuses();
      if (res.status !== 200) {
        dispatch(alertFailure(res.message));
        return;
      }
      await getUserListInfo("");
      dispatch((res.status === 200 ? alertSuccess : alertFailure)(res.message));
    } catch (err: any) {
      dispatch(alertFailure("Reload failed!"));
    }
  };

  const handleSelectCSVFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImportCSV = async () => {
    setIsLoading(true);
    try {
      const res = await importNftAllocation(selectedFile);
      if (res.status !== 200) {
        dispatch(alertFailure(res.message));
        return;
      }
      dispatch((res.status === 200 ? alertSuccess : alertFailure)(res.message));
      await getUserListInfo("");
    } catch (err: any) {
      dispatch(alertFailure("Something went wrong"));
    } finally {
      resetBonusFileInput(fileInputRef);
      setSelectedFile(null);
      setIsLoading(false);
    }
  };
  return (
    <DefaultLayout>
       {/* <Grid xs={12} md={6} className="header-left">
          <button
            className={classes.exportBtn}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={handleExportCSV}
          >
            Export to CSV
          </button>
          <button
            className={classes.exportBtn}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={() => setIsOpenBonusModal(true)}
          >
            Set bonus
          </button>
          <button
            className={classes.exportBtn}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={() => handleWipeAllBonuses()}
          >
            Wipe all bonuses
          </button>
          {/* <button
            className={classes.exportBtn}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={handleReloadCached}
          >
            Reload All
          </button> */}
        {/* </Grid> */}
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          maxWidth: "100%",
          marginTop: "15px",
          marginLeft: "12px",
        }}
      >
        <Select
          name="minTier"
          value={selectedTier}
          onChange={handleSelectedTierChange}
        >
          <MenuItem value={-1}>All Tiers</MenuItem>
          {TIERS_LABEL.map((value, index) => {
            // const adjustedIndex = index === 0 ? 0 : TIERS_LABEL.length - index;
            return (
              <MenuItem key={index} value={index}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        {/* <ExportBonusForm
          isOpenEditPopup={isOpenEditPopup}
          handleSelectedBonusChange={handleSelectedBonusChange}
          selectedBonus={selectedBonus}
          handleCreateUpdateData={handleCreateUpdateData}
          setIsOpenEditPopup={setIsOpenEditPopup}
          selectedTierBonus={selectedTierBonus}
          handleSelectedTierForBonus={handleSelectedTierForBonus}
        /> */}
        <SearchForm seachValue={query} handleSearch={handleSearch} />

        <Grid
          item
          xs={12}
          md={12}
          style={{
            // display: "flex",
            flexDirection: "row",
            alignItems: "center",
            maxWidth: "100%",
          }}
        >
          <label htmlFor="" style={{ padding: "10px", fontWeight: "600" }}>
            Import CSV NFT Allocation file
          </label>
          <input
            color="primary"
            type="file"
            accept=".csv, .xlsx"
            onChange={handleSelectCSVFile}
            ref={fileInputRef}
          />
          <button
            className={classes.exportBtn}
            onClick={handleImportCSV}
            style={{ right: "60px", color: "#000" }}
            disabled={!selectedFile}
          >
            {isLoading ? (
              <div className={classes.loader}></div>
            ) : (
              "Import NFT Allocation"
            )}
          </button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={"100%"} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader: string, index: number) => (
                  <TableCell key={index} className={classes.tableHeader}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {users &&
                users.length > 0 &&
                users.map((user: any, index: number) => (
                  <UserRow
                    key={user.id}
                    user={user}
                    getUserListInfo={getUserListInfo}
                  />
                ))}
            </TableBody>
          </Table>
        )}
        {failure ? (
          <p className={classes.errorMessage}>{failure}</p>
        ) : (!users || users.length === 0) && !loading ? (
          <p className={classes.noDataMessage}>There is no data</p>
        ) : (
          <>
            {users && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classes.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
      <SetBonusForm
        isOpen={isOpenBonusModal}
        setIsOpen={setIsOpenBonusModal}
        getUserListInfo={getUserListInfo}
      />
      {/* <FileExport /> */}
    </DefaultLayout>
  );
};

export default WhiteListNft;
